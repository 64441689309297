import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FrameTwo = _resolveComponent("FrameTwo")!
  const _component_FrameOne = _resolveComponent("FrameOne")!
  const _component_FrameThree = _resolveComponent("FrameThree")!
  const _component_FrameFour = _resolveComponent("FrameFour")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FrameTwo),
    _createVNode(_component_FrameOne),
    _createVNode(_component_FrameThree),
    _createVNode(_component_FrameFour)
  ]))
}